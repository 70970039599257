import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          // "Noob",
          // "Ultra Noob",
          "Photo Shopper",
          "Influencer",
          "Digital Marketer",
          "Digital Creator",
          "Photo Grapher",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
