import React from "react";
// import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
// import ssdhq from "../../Assets/Projects/srisai.png";

const Img = (props) => {
  // const params = useParams();
  return (
    <div>
      {/* <img src={props.img} alt={props.id} />
      <p>{params.id}</p> */}
      <Link to="/project/srisaidiya">
        <img
          src={
            "https://drive.google.com/uc?export=download&id=18wU-KuZ8Bm37oPr8VkgZ578pmRXTf2Y9"
          }
          alt="Logo"
        />
      </Link>
    </div>
  );
};

export default Img;
