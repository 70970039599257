import React from "react";
import { Container } from "react-bootstrap";

const WhatWeDo = () => {
  return (
    <Container fluid className="about-section">
      <Container>
        <iframe
          title="laptop- honor"
          sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
          style={{ width: "120px", height: "240px", margin: "10px" }}
          marginwidth="0"
          marginheight="0"
          //   scrolling="no"
          frameborder="0"
          src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=yogesh06de-21&language=en_IN&marketplace=amazon&region=IN&placement=B0BV6YHJMV&asins=B0BV6YHJMV&linkId=ee5428a273253220c27baf1fbafc7797&show_border=true&link_opens_in_new_window=true"
        ></iframe>
        {/* </Container>

      <Container> */}
        <iframe
          sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
          style={{ width: "120px", height: "240px", margin: "10px" }}
          title="laptop-hp "
          marginwidth="0"
          marginheight="0"
          //   scrolling="no"
          frameborder="0"
          src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=yogesh06de-21&language=en_IN&marketplace=amazon&region=IN&placement=B0C49BXKNL&asins=B0C49BXKNL&linkId=0c2c0ddb9af55bc4ae5bdc20cdc122d1&show_border=true&link_opens_in_new_window=true"
        ></iframe>
      </Container>
    </Container>
  );
};

export default WhatWeDo;
